const beiraReference = "https://sp.univ-unita.eu/Shibboleth.sso/Login?target=https://sp.univ-unita.eu/secure&entityID=https://idp.ubi.pt/idp/shibboleth";
const pauReference = "https://sp.univ-unita.eu/Shibboleth.sso/Login?target=https://sp.univ-unita.eu/secure&entityID=https://idp.univ-pau.fr/idp/shibboleth";
const savoieReference = "https://sp.univ-unita.eu/Shibboleth.sso/Login?target=https://sp.univ-unita.eu/secure&entityID=https://shibboleth.univ-savoie.fr/idp/shibboleth";
const uvtReference = "https://sp.univ-unita.eu/Shibboleth.sso/Login?target=https://sp.univ-unita.eu/secure&entityID=https://login.e-uvt.ro/aai/saml2/idp/metadata.php";
const torinoReference = "https://sp.univ-unita.eu/Shibboleth.sso/Login?target=https://sp.univ-unita.eu/secure&entityID=https://idp-unito-prod.cineca.it/idp/shibboleth";
const zaragozaReference = "https://sp.univ-unita.eu/Shibboleth.sso/Login?target=https://sp.univ-unita.eu/secure&entityID=idp-sir.unizar.es";

export function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}  

export {
    beiraReference,
    pauReference,
    savoieReference,
    uvtReference,
    torinoReference,
    zaragozaReference
};
