import React, { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import Cookies from 'js-cookie';


const ReplaceWithApp = () => {
    let token = Cookies.get('USER_TOKEN');

    useEffect(() => {
        if (isAndroid) {
          const url =
            "app://unita-mobile-app.univ-unita.eu/?token="+token;
    
          window.location.replace(url);
        } else if (isIOS) {
          window.location.replace("instagram://");
    
          setTimeout(() => {
            window.location.replace(
              "https://apps.apple.com/us/app/instagram/id389801252"
            );
          }, 10000);
        } else {
          window.location.replace("https://instagram.com");
        }
      }, []);
    
      return (
        <div className="App">
          <div>
            If you have not been automatically redirected, click on the following link:
          </div>
          {isAndroid ? (
          <a href={"app://unita-mobile-app.univ-unita.eu/?token="+token}>
              Open Android app
            </a>
          ) : isIOS ? (
            <a href="https://apps.apple.com/us/app/instagram/id389801252">
              Open iOS app
            </a>
          ) : (
            <a href="https://instagram.com">Open Web app</a>
          )}
        </div>
      );
};

export default ReplaceWithApp;