import { FormControl, Grid, MenuItem, OutlinedInput, Select } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { StyledSeachItem } from "../../Styles/MUIStyled";
import { buttonColors } from "../../Styles/styleConstants";
// import { buttonColors } from "../../Styles/styleConstants";

const VirtualMobilitiesSearchItem = ({placeholder, values, state, setState}) => {
    return (
        <Grid item xs={4}>
            <Box style={{width: "100%"}}>
                <FormControl fullWidth size="small">
                    
                    <Select
                        displayEmpty
                        sx={{backgroundColor: "white"}}
                        value={state}
                        onChange={event => setState(event.target.value)}
                        input={<StyledSeachItem value={state} placeholder={placeholder} margin='none' inputProps={{ 'aria-label': 'description' }} />}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <em style={{color: buttonColors.lightTheme.background}}>{placeholder}</em>;
                            }
                            return selected;
                          }}
                    >
                        <MenuItem
                            key={placeholder}
                            value=""
                        >
                            <em>None</em>
                        </MenuItem>
                        {values.map((value, index) => 
                            <MenuItem
                                key={value + index}
                                value={value}
                            >
                                {value}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Box>
        </Grid>
    );
};

export default VirtualMobilitiesSearchItem;