import { createSlice } from '@reduxjs/toolkit';

export const languageSlice = createSlice({
	name: 'language',
	initialState: [
		{ 
			id: 1, title: 'English', selected: true,
			text: {dialogTitleText: "Agreement", dialogBodyText: "I agree that my login data will be transferred to the UNITA SSO, under the UNITA GDPR Agreement that can be consulted here:", rememberChoiceText: "Remember my choice", dialogDisagreeText: "Disagree", dialogAgreeText: "Agree"},
			contactText: {contactTitle: "Contact us", coordinator: "Coordinator", engineer: "IT Engineer", team: "Software development team"},
			poweredByText: "Powered by", localLogin: "Local Login", passwordMustMatch: "Passwords must match",
			username: "Username", password: "Password", logIn: "Log in", youDontHaveAnAccount: "You don't have an account?", register: "Register",
			wrong:"Wrong", close: "close", passwordsMustMatch: "Passwords must match.", firstName: "First name", lastName: "Last name", affiliation: "Affiliation",
			rePassword: "Re-password", pupil: "Pupil", partner: "Partner", other: "Other", userExistsWithSame: "User exists with same", logOut: "Log Out", typeUser: "User type", language: "Language",
			support: "Support", service: "Service", serviceDescription: "The provider of technical support", wpDescription: "Contact people for possible developments / support",
			mobilityColumns: {"UNIVERSITY": "UNIVERSITY", "DISCIPLINARY FIELDS": "DISCIPLINARY FIELDS", "Title of the course": "Title of the course", "Study level of the course (Bachelor, Master, PhD)": "Study level of the course (Bachelor, Master, PhD)", "Number of ECTS": "Number of ECTS", "Semester (first/ second/ both)": "Semester (first/ second/ both)", "Open to students study cycle": "Open to students study cycle", "Prerequisites for students": "Prerequisites for students", "Language of instruction (FR = French, IT=Italian, ES=Spanish, RO=Romanian, PT=Portuguese)": "Language of instruction (FR = French, IT=Italian, ES=Spanish, RO=Romanian, PT=Portuguese)", "Language level required (B1, B2 = Intermediate ; C1, C2 = Advanced)": "Language level required (B1, B2 = Intermediate ; C1, C2 = Advanced)", "Fully Asynchronous / Fully Synchronous / Hybrid": "Fully Asynchronous / Fully Synchronous / Hybrid", "Evaluation method (written exam, oral interview, individual project, team project, paper, other-please specify)": "Evaluation method (written exam, oral interview, individual project, team project, paper, other-please specify)", "Approximate examination period": "Approximate examination period", "Course description": "Course description", "Web Link to course description": "Web Link to course description", "Contact of the course responsible (institutional email address)": "Contact of the course responsible (institutional email address)"},
			mobileApp: {linkText: "Mobile App", mobileVC: "Mobile Virtual Campus", buttonCaption: "To install the mobile app, please download the .apk file from the following link", QRCaption: "Or scan the following QR code"}
		},
		{ 
			id: 2, title: 'Portuguese', selected: false, 
			text: {dialogTitleText: "Acordo", dialogBodyText: "Concordo que meus dados de login serão transferidos para o SSO da UNITA, de acordo com o Acordo GDPR da UNITA, que pode ser consultado aqui:", rememberChoiceText: "Lembre-se da minha escolha", dialogDisagreeText: "Discordo", dialogAgreeText: "Concordo"}, 
			contactText: {contactTitle: "Contate-Nos", coordinator: "Coordenador", engineer: "Engenheiro de TI", team: "Equipe de desenvolvimento de software"}, 
			poweredByText: "Seu portal", localLogin: "Login Local", passwordMustMatch: "As senhas devem corresponder", 
			username: "Nombre de usuario", password: "Senha", logIn: "Conecte-se", youDontHaveAnAccount: "Você não tem uma conta?", register: "Registro", 
			wrong:"Errado", close: "fechar", passwordsMustMatch: "As senhas devem corresponder.", firstName: "Primeiro nome", lastName: "Último nome", affiliation: "Afiliação", 
			rePassword: "Re-senha", pupil: "Aluno", partner: "Parceiro", other: "De outros", userExistsWithSame: "O usuário existe com o mesmo", logOut: "Sair", typeUser: "Tipo de usuário", language: "Língua",
			support: "Suporte", service: "Serviço", serviceDescription: "O provedor de suporte técnico", wpDescription: "Entre em contato com as pessoas para possíveis desenvolvimentos/suporte",
			mobilityColumns: {"UNIVERSITY": "UNIVERSIDADE", "DISCIPLINARY FIELDS": "CAMPOS DISCIPLINARES", "Title of the course": "Título do curso", "Study level of the course (Bachelor, Master, PhD)": "Nível de estudo do curso (bacharelado, mestrado, doutorado)", "Number of ECTS": "Número de ECTS", "Semester (first/ second/ both)": "Semestre (primeiro/segundo/ambos)", "Open to students study cycle": "Ciclo de estudos aberto a estudantes", "Prerequisites for students": "Pré-requisitos para estudantes", "Language of instruction (FR = French, IT=Italian, ES=Spanish, RO=Romanian, PT=Portuguese)": "Língua de ensino (FR = francês, IT = italiano, ES = espanhol, RO = romeno, PT = português)", "Language level required (B1, B2 = Intermediate ; C1, C2 = Advanced)": "Nível de idioma necessário (B1, B2 = Intermediário; C1, C2 = Avançado)", "Fully Asynchronous / Fully Synchronous / Hybrid": "Totalmente assíncrono / totalmente síncrono / híbrido", "Evaluation method (written exam, oral interview, individual project, team project, paper, other-please specify)": "Método de avaliação (exame escrito, entrevista oral, projeto individual, projeto de equipe, trabalho, outro-especifique)", "Approximate examination period": "Período aproximado de exams", "Course description": "Descrição do Curso", "Web Link to course description": "Link da Web para a descrição do curso", "Contact of the course responsible (institutional email address)": "Contato do responsável pelo curso (endereço de e-mail institucional)"},
			mobileApp: {linkText: "Aplicativo Móvel", mobileVC: "Campus Virtual Móvel", buttonCaption: "Para instalar o aplicativo móvel, faça o download do arquivo .apk no link a seguir", QRCaption: "Ou digitalize o seguinte código QR"}
		},
		{ 
			id: 3, title: 'French', selected: false, 
			text: {dialogTitleText: "Accord", dialogBodyText: "J'accepte que mes données de connexion soient transférées à l'UNITA SSO, dans le cadre de l'UNITA GDPR Agreement qui peut être consulté ici:", rememberChoiceText: "Souviens-toi de mon choix", dialogDisagreeText: "Désaccord", dialogAgreeText: "Accord"}, 
			contactText: {contactTitle: "Contactez-nous", coordinator: "Coordinateur", engineer: "Ingénieur informatique", team: "Equipe de développement logiciel"}, 
			poweredByText: "Votre portail", localLogin: "Connexion locale", passwordMustMatch: "Les mots de passe doivent correspondre", 
			username: "Nom d'utilisateur", password: "Le mot de passe", logIn: "Connexion", youDontHaveAnAccount: "Vous n'avez pas de compte?", register: "S'inscrire", 
			wrong:"Tort", close: "proche", passwordsMustMatch: "Les mots de passe doivent correspondre.", firstName: "Prénom", lastName: "Nom de famille", affiliation: "Affiliation", 
			rePassword: "Re-mot de passe", pupil: "Élève", partner: "Partenaire", other: "Autre", userExistsWithSame: "L'utilisateur existe avec le même", logOut: "Se déconnecter", typeUser: "Saisir l'utilisateur", language: "Langue",
			support: "Support", service: "Service", serviceDescription: "Le fournisseur de support technique", wpDescription: "Personnes de contact pour d'éventuels développements / support",
			mobilityColumns: {"UNIVERSITY": "UNIVERSITÉ", "DISCIPLINARY FIELDS": "CHAMPS DISCIPLINAIRES", "Title of the course": "Titre du cours", "Study level of the course (Bachelor, Master, PhD)": "Niveau d'étude du cursus (Licence, Master, Doctorat)", "Number of ECTS": "Nombre d'ECTS", "Semester (first/ second/ both)": "Semestre (premier/deuxième/les deux)", "Open to students study cycle": "Ouvert aux étudiants du cycle d'études", "Prerequisites for students": "Prérequis pour les étudiants", "Language of instruction (FR = French, IT=Italian, ES=Spanish, RO=Romanian, PT=Portuguese)": "Langue d'enseignement (FR = français, IT = italien, ES = espagnol, RO = roumain, PT = portugais)", "Language level required (B1, B2 = Intermediate ; C1, C2 = Advanced)": "Niveau de langue requis (B1, B2 = Intermédiaire ; C1, C2 = Avancé)", "Fully Asynchronous / Fully Synchronous / Hybrid": "Entièrement asynchrone / entièrement synchrone / hybride", "Evaluation method (written exam, oral interview, individual project, team project, paper, other-please specify)": "Mode d'évaluation (examen écrit, entretien oral, projet individuel, projet d'équipe, papier, autre-veuillez préciser)", "Approximate examination period": "Période d'examen approximative", "Course description": "Description du cours", "Web Link to course description": "Lien Web vers la description du cours", "Contact of the course responsible (institutional email address)": "Contact du responsable du cours (adresse e-mail institutionnelle)"},
			mobileApp: {linkText: "Application Mobile", mobileVC: "Campus Virtuel Mobile", buttonCaption: "Pour installer l'application mobile, veuillez télécharger le fichier .apk à partir du lien suivant", QRCaption: "Ou scannez le code QR suivant"}
		},
		{
			id: 4, title: 'Romanian', selected: false, 
			text: {dialogTitleText: "Acord", dialogBodyText: "Sunt de acord ca datele mele de conectare sa fie transferate către UNITA SSO, în conformitate cu Acordul UNITA GDPR care poate fi consultat aici:", rememberChoiceText: "Memorează-mi opțiunea", dialogDisagreeText: "Dezacord", dialogAgreeText: "Acord"}, 
			contactText: {contactTitle: "Contactează-ne", coordinator: "Coordonator", engineer: "Inginer IT", team: "Echipa de dezvoltare software"}, 
			poweredByText: "Cu sprijinul ", localLogin: "Autentificare locala", passwordMustMatch: "Parolele trebuie sa fie identice", 
			username: "Nume utilizator", password: "Parola", logIn: "Logare", youDontHaveAnAccount: "Nu aveti un cont?", register: "Inregistrare", 
			wrong:"Gresit", close: "inchis", passwordsMustMatch: "Parolele trebuie sa se potriveasca.", firstName: "Prenume:", lastName: "Numele de familie", affiliation: "Afiliere", 
			rePassword: "Parola rescrisa", pupil: "Elev", partner: "Partener", other: "Altceva", userExistsWithSame: "Exista utilizator cu acelasi ", logOut: "Deconectare", typeUser: "Tip utilizator", language: "Limba",
			support: "Suport", service: "Serviciu", serviceDescription: "Furnizorul de suport tehnic", wpDescription: "Oameni de contact pentru posibile dezvoltări / suport",
			mobilityColumns: {"UNIVERSITY": "UNIVERSITATE", "DISCIPLINARY FIELDS": "DOMENIILE DISCIPLINARE", "Title of the course": "Titlul cursului", "Study level of the course (Bachelor, Master, PhD)": "Nivelul de studiu al cursului (Licență, Master, Doctorat) ",  "Number of ECTS": "Numărul de ECTS", "Semester (first/ second/ both)": "Semestrul (primul/ al doilea/ ambele) ", "Open to students study cycle": "Ciclul de studii destinat studenților", "Prerequisites for students": "Condiții preliminare pentru studenți", "Language of instruction (FR = French, IT=Italian, ES=Spanish, RO=Romanian, PT=Portuguese)": "Limba de predare (FR = franceza, IT = italiana, ES = spaniola, RO = româna, PT = portugheza) ", "Language level required (B1, B2 = Intermediate ; C1, C2 = Advanced)": "Cunoștințele de limbă necesare (B1, B2 = Intermediar; C1, C2 = Avansat) ", "Fully Asynchronous / Fully Synchronous / Hybrid": "Complet asincron / complet sincron / hibrid", "Evaluation method (written exam, oral interview, individual project, team project, paper, other-please specify)": "Metoda de evaluare (examen scris, interviu oral, proiect individual, proiect în echipă, lucrare, altele - vă rugăm să specificați)", "Approximate examination period": "Perioada aproximativă de examinare", "Course description": "Descrierea cursului", "Web Link to course description": "Link web pentru descrierea cursului", "Contact of the course responsible (institutional email address)": "Contactul responsabilului cursului (adresa de e-mail instituțională) "},
			mobileApp: {linkText: "Aplicație Mobilă", mobileVC: "Campus Virtual Mobil", buttonCaption: "Pentru a instala aplicația mobilă, vă rugăm să descărcați fișierul .apk de la următorul link", QRCaption: "Sau scanați următorul cod QR"}
		},
		{
			id: 5, title: 'Italian', selected: false, 
			text: {dialogTitleText: "Accordo", dialogBodyText: "Accetto che i miei dati di accesso vengano trasferiti all'UNITA SSO, ai sensi dell'Accordo GDPR dell'UNITA che può essere consultato qui:", rememberChoiceText: "Ricorda la mia scelta", dialogDisagreeText: "Disaccetto", dialogAgreeText: "Accetto"}, 
			contactText: {contactTitle: "Contattaci", coordinator: "Coordinatore", engineer: "Ingegnere IT", team: "Team di sviluppo software"}, 
			poweredByText: "Il tuo portale", localLogin: "Accesso locale", passwordMustMatch: "Le password devono essere identiche", 
			username: "Nome utente", password: "Parola d'ordine", logIn: "Accesso", youDontHaveAnAccount: "Non hai un account?", register: "Registrati", 
			wrong:"Sbagliato", close: "chiudere", passwordsMustMatch: "Le password devono essere identiche.", firstName:"Nome di battesimo", lastName: "Cognome", affiliation: "Affiliazione", 
			rePassword: "Ri-password", pupil: "Allievo", partner: "Compagno", other: "Altro", userExistsWithSame: "L'utente esiste con lo stesso", logOut: "Disconnettersi", typeUser: "Digita utente", language: "Lingua",
			support: "Supporto", service: "Servizio", serviceDescription: "Il fornitore del supporto tecnico", wpDescription: "Contattare le persone per possibili sviluppi/supporto",
			mobilityColumns: {"UNIVERSITY": "UNIVERSITÀ", "DISCIPLINARY FIELDS": "CAMPI DISCIPLINARI", "Title of the course": "Titolo del corso", "Study level of the course (Bachelor, Master, PhD)": "Livello di studio del corso (Bachelor, Master, PhD) ", "Number of ECTS": "Numero di CFU", "Semester (first/ second/ both)": "Semestre (primo/secondo/entrambi) ", "Open to students study cycle": "Ciclo di studio aperto agli studenti", "Prerequisites for students": "Prerequisiti per gli studenti", "Language of instruction (FR = French, IT=Italian, ES=Spanish, RO=Romanian, PT=Portuguese)": "Lingua di insegnamento (FR = francese, IT=italiano, ES=spagnolo, RO=rumeno, PT=portoghese)", "Language level required (B1, B2 = Intermediate ; C1, C2 = Advanced)": "Livello di lingua richiesto (B1, B2 = Intermedio ; C1, C2 = Avanzato) ", "Fully Asynchronous / Fully Synchronous / Hybrid": "Completamente asincrono/completamente sincrono/ibrido", "Evaluation method (written exam, oral interview, individual project, team project, paper, other-please specify)": "Modalità di valutazione (esame scritto, colloquio orale, progetto individuale, progetto di gruppo, elaborato, altro-specificare)", "Approximate examination period": "Periodo di esame approssimativo", "Course description": "Descrizione del corso", "Web Link to course description": "Collegamento Web alla descrizione del corso", "Contact of the course responsible (institutional email address)": "Contatto del responsabile del corso (indirizzo email istituzionale) "},
			mobileApp: {linkText: "App Mobile", mobileVC: "Campus Virtuale Mobile", buttonCaption: "Per installare l'app mobile, si prega di scaricare il file .apk dal seguente link", QRCaption: "Oppure scansiona il seguente codice QR"}
		},
		{
			id: 6, title: 'Spanish', selected: false, 
			text: {dialogTitleText: "Convenio", dialogBodyText: "Acepto que mis datos de inicio de sesión se transferirán al SSO de UNITA, en virtud del Acuerdo GDPR de UNITA que se puede consultar aquí:", rememberChoiceText: "Recuerda mi elección", dialogDisagreeText: "Disacepto", dialogAgreeText: "Acepto"}, 
			contactText: {contactTitle: "Contacta con nosotras", coordinator: "Coordinador", engineer: "Ingeniero informático", team: "Equipo de desarrollo de software"}, 
			poweredByText: "Tu portal", localLogin: "Inicio de sesión local", passwordMustMatch: "Las contraseñas deben coincidir", 
			username: "Nombre de usuario", password: "Contraseña", logIn: "Iniciar sesión", youDontHaveAnAccount: "¿No tienes una cuenta?", register: "Registrarse", 
			wrong:"Incorrecto", close: "cerrar", passwordsMustMatch: "Las contraseñas deben coincidir.", firstName:"Nombre de pila", lastName: "Apellido", affiliation: "Afiliación", 
			rePassword: "Cambiar contraseña", pupil: "Alumno", partner: "Pareja", other: "Otro", userExistsWithSame: "El usuario existe con el mismo", logOut: "Cerrar sesión", typeUser: "Tipo de usuario", language: "Idioma",
			support: "Soporte", service: "Servicio", serviceDescription: "El proveedor de soporte técnico", wpDescription: "Personas de contacto para posibles desarrollos/soporte",
			mobilityColumns: {"UNIVERSITY": "UNIVERSIDAD", "DISCIPLINARY FIELDS": "CAMPOS DISCIPLINARIOS", "Title of the course": "Titulo del curso", "Study level of the course (Bachelor, Master, PhD)": "Nivel de estudio del curso (Licenciatura, Maestría, Doctorado)",  "Number of ECTS": "Número de ECTS", "Semester (first/ second/ both)": "Semestre (primera / segunda / ambas)", "Open to students study cycle": "Ciclo de estudio abierto a estudiantes", "Prerequisites for students": "Requisitos previos para las estudiantes", "Language of instruction (FR = French, IT=Italian, ES=Spanish, RO=Romanian, PT=Portuguese)": "Idioma de instrucción (FR = francés, IT = italiano, ES = español, RO = rumano, PT = portugués)", "Language level required (B1, B2 = Intermediate ; C1, C2 = Advanced)": "Nivel de idioma requerido (B1, B2 = Intermedio; C1, C2 = Avanzado)", "Fully Asynchronous / Fully Synchronous / Hybrid": "Totalmente asíncrono/totalmente síncrono/híbrido", "Evaluation method (written exam, oral interview, individual project, team project, paper, other-please specify)": "Método de evaluación (examen escrito, entrevista oral, proyecto individual, proyecto de equipo, papel, otro-por favor especifique)", "Approximate examination period": "Período de examen aproximado", "Course description": " Descripción del curso", "Web Link to course description": "Enlace web a la descripción del curso", "Contact of the course responsible (institutional email address)": "Contacto del responsable del curso (dirección de correo electrónico institucional)"},
			mobileApp: {linkText: "Aplicación Movil", mobileVC: "Campus Virtual Móvil", buttonCaption: "Para instalar la aplicación móvil, descargue el archivo .apk desde el siguiente enlace", QRCaption: "O escanea el siguiente código QR"}
		}
	],
	reducers: {
		selectLanguage: (state, action) => {
			let oldLang = state.find(lang => lang.selected === true);
			oldLang.selected = false;
			let newLang = state.find(lang => lang.id === action.payload.id);
			newLang.selected = true;
			localStorage.removeItem("persist:root");
			localStorage.setItem("language", newLang.id);
		},
	},
});

export const { selectLanguage } = languageSlice.actions;

export default languageSlice.reducer;