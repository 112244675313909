import unita_logo from "../Img/unita_logo.png"
import unita_bw_logo from "../Img/unita_bw_logo.jpg"
import GenericCard from "./GenericCard";
import mediaWiki from "../Img/MediaWiki_logo.png"
import limeSurveyLogo from "../Img/lime_survey.png";
import limeSurveySmall from "../Img/lime_survey_small.png";
import analyticsLogoSmall from "../Img/matomo_logo.png";
import analyticsLogo from "../Img/Matomo_logo_big.png";
import socialLogo from "../Img/humhub_logo.jpeg";
import socialLogoSmall from "../Img/humhub_logo_small.jpg";
import teachhingProgramsLogo from "../Img/logounita_TeachingPrograns.png"
import researchUnits from "../Img/logounitaResearchunits.png"
import teachingProgramsLogo from "../Img/logounita_TeachingPrograns.png"
import researchUnitsLogo from "../Img/logounitaResearchunits.png"
import moodle_logo from "../Img/moodle_logo.png";
import moodle_logo_big from "../Img/moodle_logo_big.png";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { PageHolder, DashboardGridHolder} from '../Styles/styledComponentStyles';
import Cookies from 'js-cookie';
import { logOut } from "../Redux/Slices/userSlice"
import { useDispatch } from 'react-redux';
import { selectLanguage } from "../Redux/Slices/languageSlice";
import { useState } from "react";

export default function Dashboard(props) {

    let dispatch = useDispatch();
    let userSelector = useSelector((state) => state.user.userData);
  
    let token = Cookies.get('USER_TOKEN');
	const [redirectToAppState, setRedirectToAppState] = useState(false);

    useEffect(() => {
		let data;  
		let country = "com";
		if (userSelector && userSelector.email) {
			country = userSelector.email.split(".")[1];
		}

		switch (country) {
			case "com":
				data = 1;
				break;
			case "pt":
				data = 2;
				break;
			case "fr":
				data = 3;
				break;
			case "ro":
				data = 4;
				break;
			case "it":
				data = 5;
				break;
			case "es":
				data = 6;
				break;
			default:
				data = 1;
				break;    
		}

		dispatch(
			selectLanguage({
				id: data
			})
    	);

		if (localStorage.getItem('mobile')) {
			localStorage.removeItem('mobile');
			// console.log('hello from mobile');
			setRedirectToAppState(true);
		}
	});

	const limeSurvey = "https://surveysbg.univ-unita.eu/index.php/admin/authentication/sa/login";
	const APForum = "https://forumsbg.univ-unita.eu/";
	// const wiki = "https://wiki.unitassotest.uvt.ro/index.php?title=Special:UserLogin&returnto=Main+Page";
	// const social = "https://social.unitassotest.uvt.ro/";
	const virtualMobilities = "https://unita-virtual-mobility.vercel.app";
	// const analyticsURL = "https://analytics.unitassotest.uvt.ro/";
	const cartographyURL = "https://unita-app.vercel.app/";
	// const datacloudURL = "https://datacloud.univ-unita.eu/index.php/login";

	const laboratories = "https://labos.vercel.app/"

	const thematicHubsURL = "https://enquetes.u-bordeaux.fr/UPPA-SOFT/UNITA_RI_Carto/Carto.htm?Pwd=UNITA";
	const reUnitaResearchURL = "https://reunitaresearchinfrastructure.i3a.es/"
	const moodleURL = "https://edu.univ-unita.eu/course/index.php?categoryid=6";

	const trainingURL = "https://univ-unita.eu/Ficheiros/Sites/86/Paginas/11604/UNITA%20TLC%20Offer%202024.pdf";
	const raciURL = "https://itmatrix.univ-unita.eu/";

	if (!token) {
		dispatch(
			logOut()
		);
	}
  
	if(!userSelector){
		return <Redirect to="/" />;
	}

	if(redirectToAppState) {
		return <Redirect to="/ReplaceWithMobile" />;
	}

	return(
		<PageHolder>
			<DashboardGridHolder>
				{/* <GenericCard
					imagePath={socialLogo}
					miniImagePath={socialLogoSmall}
					title={"Social Network"}
					reference={social}
				/> */}
				<GenericCard
					imagePath={limeSurveyLogo}
					miniImagePath={limeSurveySmall}
					title={"Surveys"}
					reference={limeSurvey}
				/>
				<GenericCard
					imagePath={unita_logo}
					miniImagePath={unita_logo}
					title={"Forum"}
					reference={APForum}
				/>
				<GenericCard
					imagePath={researchUnitsLogo}
					miniImagePath={researchUnitsLogo}
					title={"Laboratories"}
					reference={laboratories}
				/>
				<GenericCard 
					imagePath={unita_bw_logo}
					miniImagePath={unita_bw_logo}
					title={"Virtual Mobilities"}
					reference={virtualMobilities}
				/>
				<GenericCard 
					imagePath={teachingProgramsLogo}
					miniImagePath={teachingProgramsLogo}
					title={"Course cartography"}
					reference={cartographyURL}
				/>
				{/* <GenericCard
					imagePath={mediaWiki}
					miniImagePath={mediaWiki}
					title={"Wiki"}
					reference={wiki}
				/> */}
				<GenericCard // Ex-Thematic Hubs
					imagePath={unita_logo}
					miniImagePath={unita_logo}
					title={"Research cartography"}
					reference={thematicHubsURL}
				/>
				<GenericCard 
					imagePath={unita_bw_logo}
					miniImagePath={unita_bw_logo}
					title={"Re-UNITA Research infrastructures"}
					reference={reUnitaResearchURL}
				/>
				<GenericCard 
					imagePath={moodle_logo_big}
					miniImagePath={moodle_logo}
					title={"Moodle platform"}
					reference={moodleURL}
				/>
				{/* <GenericCard 
					imagePath={analyticsLogo}
					miniImagePath={analyticsLogoSmall}
					title={"Analytics"}
					reference={analyticsURL}
				/> */}
				<GenericCard 
					imagePath={unita_bw_logo}
					miniImagePath={unita_bw_logo}
					title={"UNITA TLC Training Offer"}
					reference={trainingURL}
				/>
				<GenericCard 
					imagePath={unita_bw_logo}
					miniImagePath={unita_bw_logo}
					title={"IT department RACI matrix"}
					reference={raciURL}
				/>
			</DashboardGridHolder>
		</PageHolder>
	);
}