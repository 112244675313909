import React from "react";
import { useEffect, useState } from "react";
import { PageHolder } from '../Styles/styledComponentStyles';
import { readString } from 'react-papaparse';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import blob from "../CSV/VM_Spring_2023.csv"
import MobilityModal from "./MobilityModal";
import { TableSortLabel } from "@mui/material";
import { useSelector } from "react-redux";
import { StyledTableCell, StyledTableRow } from "../Styles/MUIStyled";
import VirtualMobilitiesSearchBar from "./VirtualMobilitiesComponents/VirtualMobilitiesSearchBar";
import { Redirect } from "react-router-dom";
import { cardColors } from "../Styles/styleConstants";


const VirtualMobilities = () => {
    const [mobilities, setMobilities] = useState(null);
    const [filteredMobilities, setFilteredMobilities] = useState(null);
    
    // const languageSelector = useSelector((state) => {
    //     let languages = state.languages;
    //     let selectedLang = languages.find((lang) => lang.selected);
    //     return selectedLang;
    // });

    let userSelector = useSelector((state) => state.user.userData);

    const [uniCriteria, setUniCriteria] = useState("");
    const [discFieldsCriteria, setDiscFieldsCriteria] = useState("");
    const [studyLevelCriteria, setStudyLevelCriteria] = useState("");
    // const [semesterCriteria, setSemesterCriteria] = useState("");
    // const [langCriteria, setLangCriteria] = useState("");
    const [teachingModalityCriteria, setTeachingModalityCriteria] = useState("");

    useEffect(() => {
        const parseFile = csvFile => {
            readString(csvFile, {
                download: true,
                header: true,
                delimiter: ",",
                linebreak: "\n",
                dynamicTyping: true,
                complete: (results, file) => {
                    setMobilities(results);
                    setFilteredMobilities(results.data);
                }
            });
        }
        parseFile(blob);
    }, []);

    useEffect(() => {
        if (mobilities) {
            setFilteredMobilities(mobilities.data.filter((mobility) => mobility["UNIVERSITY"].toLowerCase().includes(uniCriteria.toLowerCase())));
            setPage(0)
        }
    }, [uniCriteria, mobilities])

    useEffect(() => {
        if (mobilities) {
            setFilteredMobilities(mobilities.data.filter((mobility) => mobility["DISCIPLINARY FIELDS"].toLowerCase().includes(discFieldsCriteria.toLowerCase())));
            setPage(0)
        }
    }, [discFieldsCriteria, mobilities])

    useEffect(() => {
        if (mobilities) {
            setFilteredMobilities(mobilities.data.filter((mobility) => mobility["Title of the course"]?.toLowerCase().includes(studyLevelCriteria.toLowerCase())));
            setPage(0)
        }
    }, [studyLevelCriteria, mobilities])

    // useEffect(() => {
    //     if (mobilities) {
    //         setFilteredMobilities(mobilities.data.filter((mobility) => mobility["Study level of the course"]?.toLowerCase().includes(semesterCriteria.toLowerCase())));
    //         setPage(0)
    //     }
    // }, [semesterCriteria, mobilities])

    // useEffect(() => {
    //     if (mobilities) {
    //         setFilteredMobilities(mobilities.data.filter((mobility) => mobility["Semester"].toLowerCase().includes(langCriteria.toLowerCase())));
    //         setPage(0)
    //     }
    // }, [langCriteria, mobilities])

    useEffect(() => {
        if (mobilities) {
            setFilteredMobilities(mobilities.data.filter((mobility) => mobility["Teaching modality"]?.toLowerCase().includes(teachingModalityCriteria.toLowerCase())));
            setPage(0)
        }
    }, [teachingModalityCriteria, mobilities])

    const searchCriterias = [
        {
            placeholder: "University",
            values: "UBI;UNIZAR;UPPA;USMB;UNITO;UVT".split(';'),
            state: uniCriteria,
            setState: setUniCriteria
        },
        {
            placeholder: "Disciplinary fields",
            values: "Education;Arts and humanities;Social sciences, journalism and information;Business, administration and law;Natural sciences, mathematics and statistics;Information and Communication Technologies (ICTs);Engineering, manufacturing and construction;Agriculture, forestry, fisheries and veterinary;Health and welfare;Services".split(';'),
            state: discFieldsCriteria,
            setState: setDiscFieldsCriteria
        },
        {
            placeholder: "Study level of the course",
            values: "Bachelor;Master;PhD".split(';'),
            state: studyLevelCriteria,
            setState: setStudyLevelCriteria
        },
        // {
        //     placeholder: "Semester",
        //     values: "Autumn;Spring;Both".split(';'),
        //     state: semesterCriteria,
        //     setState: setSemesterCriteria
        // },
        // {
        //     placeholder: "Language of instruction",
        //     values: "French;Italian;Spanish;Romanian;Portuguese".split(';'),
        //     state: langCriteria,
        //     setState: setLangCriteria
        // },
        {
            placeholder: "Teaching modality",
            values: "Asynchronous;Synchronous;Hybrid".split(';'),
            state: teachingModalityCriteria,
            setState: setTeachingModalityCriteria
        },
    ];

    const fields = [
        // mandatory: [
            {field: "University"},
            {field: "Disciplinary fields"},
            {field: "Title of the course"},
            {field: "Study level of the course"},
            {field: "Semester"},
            {field: "Period of teaching (including period of assessment)", subfields: [{fields: "Starting date"}, {fields: "Ending date"}]},
            {field: "Teaching volume in hours"},
            {field: "Language of instruction"},
            {field: "Number of ECTS"},
            {field: "Teaching modality"},
            {field: "Number of assessment of the regular session"},
            {field: "Assessment method", subfields: [{fields: "Regular session"}, {fields: "Catch-up session"}]},
            {field: "Short course description (max. 150 words)"},
            {field: "Web Link to course description"},
            {field: "Contact of the course responsible (institutional email address)"},
            {field: "Number of places for international students"},
        // ],
        // optional: [
            {field: "Open to students study cycle"},
            {field: "Prerequisites for students"},
            {field: "Language level required"},
            {field: "Approximate examination period"}
        // ]
    ];

    const importantFields = [{
            name: "UNIVERSITY",
            minWidth: 200
        }, {
            name: "DISCIPLINARY FIELDS",
            minWidth: 200
        }, {
            name: "Title of the course",
            minWidth: 200
        }, {
            name: "Study level of the course",
            minWidth: 350
        }, {
            name: "Semester",
            minWidth: 250
        }, {
            name: "Language of instruction",
            minWidth: 250
        }, {
            name: "Teaching modality",
            minWidth: 250
        }
    ];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [orderBy, setOrderBy] = useState({column: "None", order: "asc"});
    const sortOptions = { sensitivity: 'base', ignorePunctuation: true };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeOfOrder = (selectedColumn) => {
        const mobilitiesCpy = {...mobilities};
        const orderByCpy = {...orderBy};

        if (orderByCpy.column === selectedColumn) {
            orderByCpy.order = orderByCpy.order === "asc" ? "desc" : "asc";
        } else {
            orderByCpy.column = selectedColumn;
            orderByCpy.order = "asc";
        }

        mobilitiesCpy.data.sort((a, b) => (orderByCpy.order === "asc") ? String(a[selectedColumn]).localeCompare(b[selectedColumn], 'en', sortOptions) : (-1) * String(a[selectedColumn]).localeCompare(b[selectedColumn], 'en', sortOptions) );

        setMobilities(mobilitiesCpy);
        setOrderBy(orderByCpy);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    if(!userSelector){
		return <Redirect to="/" />
	}

    return (
        <PageHolder>
            <div style={{ height: "90%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <div style={{ width: "90%", display: "flex", justifyContent: "center" }}>
                    {/* <FormControl sx={{ width: "70%", borderRadius: "25px" }}> */}
                    <div style={{width: "75%" }}>
                        {/* <SearchIcon color="disabled" />
                        <TextField value={filterValue} variant="standard" sx={{ width: "75%", border: "0px" }} onChange={(e) => setFilterValue(e.target.value)} InputProps={{disableUnderline: true}} />
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <FilterListIcon color="disabled" />
                        <Select
                            sx={{ width: "17%" }}
                            value={filterKey}
                            onChange={(event) => {setFilterKey(event.target.value);}}
                            input={<InputBase value={filterKey}/>}
                        >
                            {importantFields.map(column => (<MenuItem value={column.name}>{mobilityText[column.name]}</MenuItem>))}
                        </Select> */}
                        <VirtualMobilitiesSearchBar searchCriteriaStates={searchCriterias} />
                    </div>
                </div>
                {(filteredMobilities === null) ? null : 
                    <Paper sx={{ width: '80%', overflow: 'hidden', maxHeight: "75%", marginTop: "15px"}}>
                        <TableContainer sx={{ maxHeight: "90%" }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead sx={{zIndex: "1", backgroundColor: cardColors.lightTheme.gradientEnd}}>
                                    <TableRow>
                                        {importantFields.map((column) => (
                                            <StyledTableCell
                                                key={"Col" + column.name}
                                                align={"left"}
                                                style={{ minWidth: column.minWidth + "px" }}
                                            >
                                                <TableSortLabel 
                                                    active={orderBy.column === column.name}
                                                    direction={orderBy.column === column.name ? orderBy.order : 'asc'}
                                                    onClick={() => {handleChangeOfOrder(column.name)}}
                                                >
                                                    {column.name}
                                                </TableSortLabel>
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredMobilities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, indexR) => (
                                            <StyledTableRow hover role="checkbox" tabIndex={-1} key={"Row" + indexR} onClick={() => {setOpen(true); setModalData(row);}}>
                                                {importantFields.map((column, indexC) => {
                                                    const value = row[column.name];
                                                    return (
                                                        <StyledTableCell key={indexC + "-" + indexR}>
                                                            {typeof value === "string" && value.includes("http") ? <a style={{color: "blue"}} href={value} onClick={(event) => {event.stopPropagation()}}>Go to page</a> : value}
                                                        </StyledTableCell>
                                                    );
                                                })}
                                            </StyledTableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={filteredMobilities.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper> 
                }
                {modalData === null ? null : <MobilityModal open={open} setOpen={setOpen} dataObject={modalData} />}
            </div>
        </PageHolder>
    );
};

export default VirtualMobilities;
