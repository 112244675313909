
let lightBG = "#F5F5F5";
let blue1 = "#01A7C2";
let blue2 = "#6D071A";
let blue3 = "#6E081B";
let coral = "#A30B37";

const backgroundColors = {
    lightTheme: {
        gradientStart: lightBG,
        gradientEnd: lightBG
    },
    darkTheme: {}
};

const cardColors = {
    lightTheme: {
        gradientStart: lightBG,
        gradientEnd: blue2,
        textColor: lightBG
    }, // mobile: background: linear-gradient(90deg, rgba(255,255,255,1) 20%, rgba(0,124,132,1) 32%); desktop: -: linear-gradient(180deg, rgba(255,255,255,1) 50%, rgba(0,124,132,1) 71%);

    darkTheme: {}
};

const headerColors = {
    lightTheme: {
        gradientStart: lightBG,
        gradientEnd: coral,
        textColor: coral
    },
    darkTheme: {}
};

const footerColors = {
    lightTheme: {
        gradientStart: coral,
        gradientEnd: lightBG,
        textColor: coral
    },
    darkTheme: {}
};


const buttonColors = {
    lightTheme: {
        background: blue3,
        textColor: lightBG
    },
    darkTheme: {}
};

const barTextColors = {
    lightTheme: coral,
    darkTheme: "#"
};

const menuColors = {
    lightTheme: {
        backgroundColor: "#00CFDC",
        textColor: "#007C84",
    },
    darkTheme: {}
};

export {
    backgroundColors,
    cardColors,
    headerColors,
    footerColors,
    buttonColors,
    barTextColors,
    menuColors
};