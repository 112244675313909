import { Grid } from "@mui/material";
import React from "react";
import { uuidv4 } from "../../utils/utils";
import VirtualMobilitiesSearchItem from "./VirtualMobilitiesSearchItem";

// search states shall be of the following form: 
// searchCriteriaStates = [
//     {state: state1, setState: setState1, placeholder: placeholder1, values: values1},
//     {state: state2, setState: setState2, placeholder: placeholder2, values: values2},
//     {state: state3, setState: setState3, placeholder: placeholder3, values: values3},
//     ...
// ]
// There will be <numberOfRows> rows and <length(searchCriteriaStates)> / <numberOfRows> items/row
const VirtualMobilitiesSearchBar = ({searchCriteriaStates, numberOfRows = 2}) => {
    return (
        // <div style={{background: "white", borderRadius: "15px"}}>
            <Grid container spacing={2}>
                {searchCriteriaStates.map(item => 
                    <VirtualMobilitiesSearchItem
                        key={uuidv4()} 
                        placeholder={item.placeholder}
                        values={item.values}
                        state={item.state}
                        setState={item.setState}
                    />
                )}
            </Grid>
        // </div>
    );
};

export default VirtualMobilitiesSearchBar;